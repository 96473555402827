<template>
  <b-card title="Data Kehadiran">
    <b-row>
      <b-col md="12">
        <validation-observer ref="accountRules" tag="form">
          <b-form class="mt-1" @submit.prevent>
            <b-row>
              <b-col cols="12" md="3">
                <b-form-group label="Tanggal" label-for="date">
                  <validation-provider #default="{ errors }" name="date" rules="required">
                    <b-form-input id="date" v-model="dataParent.date" type="date"
                      :state="errors.length > 0 ? false : null" placeholder="Tanggal" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <validation-provider #default="{ errors }" name="class_id" rules="required">
                  <b-form-group label="Kelas" label-for="class_id" :state="errors.length > 0 ? false : null">
                    <v-select id="class_id" v-model="dataParent.class_id" :reduce="(class_id) => class_id.real_id"
                      placeholder="Pilih Kelas" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listClass"
                      label="class_desc" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <b-form-group>
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-1" @click="GetAllData">
                    View
                  </b-button>
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" class="mr-1"
                    @click="ExportExcel">
                    Export
                  </b-button>
                </b-form-group>
              </b-col>

            </b-row>

          </b-form>
        </validation-observer>
      </b-col>
    </b-row>

    <table class="table" id="recaptable">
      <thead>
        <tr>
          <th>No</th>
          <th>NISN</th>
          <th>Nama Lengkap</th>
          <th>L/P</th>
          <th>Kelas</th>
          <th>Jam Masuk</th>
          <th>Jam Keluar</th>
          <th>Keterangan</th>
          <th>Edit</th>
        </tr>
      </thead>
      <tbody>
      </tbody>
    </table>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  // BAvatar,
  BButton,
  BCard,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import { codeAdvance } from './code'

import 'jquery/dist/jquery.min.js';
import 'datatables.net';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"

import axios from 'axios';
import $ from 'jquery';

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

export default {
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BCard,
    VueGoodTable,
    BDropdown,
    BDropdownItem,
    // BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BButton,
    // Table,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  mounted() {
    $('#datatable').DataTable({
      processing: true,
      responsive: true,
    });
  },
  data: function () {
    return {
      products: [],
    }
  },
  toEditForm(pesan) {
    alert(pesan);
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      dir: false,
      codeAdvance,
      searchTerm: '',
      modalRemove: '',
      dataParent: {
        date: this.NowDateFormat(),
        class_id: ''
      },
      DataProduct: [],
      listClass: [],
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.getClass()
  },
  methods: {
    NowDateFormat() {
      var NowDate = Date.now();
      var d = new Date(NowDate),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [year, month, day].join('-');
    },
    formatDate(date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [year, month, day].join('-');
    },
    async getClass() {
      try {
        const response = await this.$http.get('/masterclasses')
        this.listClass = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Presences Not Success')
        console.log(err)
      }
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
    showMsgBoxTwo(id) {
      this.modalRemove = ''
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete Data.', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.removeData(id)
            this.modalRemove = value
          }
          this.modalRemove = value
        })
    },
    onRowClick(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `Hello user! You have clicked on row ${params.row.id}`,
          icon: 'UserIcon',
          variant: 'success',
        },
      })
    },
    toRouteAddPegawai() {
      this.$router.push({ name: 'data-pegawai-add' })
    },
    toEditForm(pesan) {
      alert(pesan);
    },

    async ExportExcel() {
      var table = $('#recaptable').DataTable();
      table.page.len(-1).draw();
      window.open('data:application/vnd.ms-excel,' +
        encodeURIComponent($('#recaptable').parent().html()));
      setTimeout(function () {
        table.page.len(10).draw();
      }, 1000)
    },

    async GetAllData() {
      try {
        var Period = document.getElementById('date').value;
        //var class_id = document.getElementById('class_id').value;   
        var dataclass = this.dataParent.class_id;
        const response = await this.$http.get(`/kehadirans?date=${Period}&class_id=${dataclass}`)
        var DataRespone = response.data.data.data;
        var number = 1;
        var RecapTemp = [];
        $.each(DataRespone, function (i, item) {
          var JsonData = {
            "no": number,
            "id": item.id,
            "student_id": item.student_id,
            "nisn": item.nisn,
            "name": item.name,
            "gender": item.gender,
            "class_id": item.class_id,
            "class_desc": item.class_desc,
            "date": item.date,
            "time_in": item.time_in,
            "time_out": item.time_out,
            "type": item.type,
            "absent_type_desc": item.absent_type_desc,
            "description": item.description,
          }
          RecapTemp.push(JsonData);
          number++;
        });
        this.DataProduct = this.RecapTemp;
        $('#recaptable').DataTable().destroy();
        var myTable = $('#recaptable').DataTable({
          "paging": true,
          "lengthChange": true,
          "searching": true,
          "ordering": true,
          "info": true,
          "autoWidth": true,
          "data" : this.JsonData,
          "columns": [{
            "title": "No",
            "data": "no"
          }, {
            "title": "NISN",
            "data": "nisn"
          }, {
            "title": "Nama Lengkap",
            "data": "name"
          },
          {
            "title": "L/P",
            "data": "gender"
          }, {
            "title": "Kelas",
            "data": "class_desc"
          }
            , {
            "title": "Jam Masuk",
            "data": "time_in"
          }
            , {
            "title": "Jam Keluar",
            "data": "time_out"
          }
            , {
            "title": "Keterangan",
            "data": "absent_type_desc"
          }, {
            "title": "Edit",
            "data": null
          }],
          // "columnDefs": [
          //   {
          //     targets: 8,
          //     render: function (data, type, full, meta) {
          //       if (type === 'display') {
          //         // data = '<button type="button" class="btn btn-info btn-sm" id="Edit" onClick="this.toEditForm()"><i class="far fa-edit"></i></button>';
          //         data = `<button onclick="this.handleClick(${full.id})">Click me</button>`;
          //       }
          //       return data;
          //     }
          //   },
          // ]
          columnDefs: [
            {
              targets: 8, // The index of the Actions column
              render: (data, type, row) => {
                return '<button class="EditButton btn btn-info btn-sm"><i class="far fa-edit"></i></button> <button class="DeleteButton btn btn-danger btn-sm"><i class="fa fa-trash"></i></button>';
              },
            },
          ],
          createdRow: (row, data, dataIndex) => {
            $(row).find('.EditButton').on('click', () => {              
              this.UpdateClick(data.id);
            });
            $(row).find('.DeleteButton').on('click', () => {              
              this.riseUpModalRemove(data.id);
            });
          },
        });
        myTable.clear();
        $.each(RecapTemp, function (index, value) {
          myTable.row.add(value);
        });
        myTable.draw();
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Data Not Success')
        console.log(err)
      }
    },
    handleClick(id) {
      // Your code here
      alert(id);
    },
    UpdateClick(id) {
      this.$router.push({ name: 'absensi-data-kehadiran-edit', params: { id: id } })
    },
    DeleteClick(id) {
      alert(id);
    },
    riseUpModalRemove(val) {
      this.showMsgBoxTwo(val)
    },
    async removeData(val) {
      try {        
        await this.$http.delete(`/kehadirans/${val}`)
        await this.GetAllData()
        this.showToast('success', 'top-center', 'Delete Data Success')
      } catch (err) {
        this.showToast('danger', 'top-center', 'Delete Data Not Success')
        console.log(err)
      }
    },
  },
}
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
